
const axios = require('axios')

export async function warframeWorldState (platform) {

    const config = {
        headers :{
          'Accept-Language' : 'en',
          "Access-Control-Allow-Origin" : '*'
        }
      }

      if (platform === 'xbox') {
        platform = 'xb1'
      } else if (platform === 'Switch') {
        platform = 'swi'
      }


    try{
        const response = await axios.get('https://api.warframestat.us/' + platform , config)
        return response.data;
    }catch(error) {
        return {};
    }
}

export async function warframeNews () {
  const config = {
    headers :{
      'Accept-Language' : 'en',
      "Access-Control-Allow-Origin" : '*'
    }
  }

  try{
    const response = await axios.get('https://api.warframestat.us/pc/news', config)
    return response.data
  }catch(error) {
    return {};

  }
}

export async function TrendingAnime () {
  const  config = {
    headers : {
      'Access-Control-Allow-Origin' : '*',
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json'
    }
  }

  try{
    const response = await axios.get('https://kitsu.io/api/edge/trending/anime',config)
    return response.data
  }catch(error){
    return {}
  }
}

export async function TrendingManga () {
  const config =  {
    headers : {
      'Access-Control-Allow-Origin' : '*',
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json'
    }
  }

  try { 
    const response = await axios.get('https://kitsu.io/api/edge/trending/manga',config)
    return response.data
  } catch (error) {
    return {}
  }
}

export async function searchAnime (query) {
  const config = {
    headers :{
      'X-RapidAPI-Key': '68d1c9a6f4mshfc4423c92ec21e3p11a588jsn688ea956c773',
      'X-RapidAPI-Host': 'myanimelist.p.rapidapi.com'
    }
  }

  try {
    const response = await axios.get('https://myanimelist.p.rapidapi.com/v2/anime/search?q='+ query ,config)
    console.log(response.data)
    return response.data
  } catch {
    
  }
}


export async function searchManga (query) {

  const query2 = query.replace(' ', '_')
  const config = {
    params: {keyword: query2, page: '1'},
    headers :{
      'X-RapidAPI-Key': 'fc90f3caeamshf9546c49fe16bd5p1f62f0jsnc70438f66ae3',
      'X-RapidAPI-Host': 'manga-scraper-for-mangakakalot-website.p.rapidapi.com'
    }
  }

  try {
    const response = await axios.get('https://manga-scraper-for-mangakakalot-website.p.rapidapi.com/search',config)
    
    return response.data
  } catch {
    
  }
}


export async function searchYugiohCard (query) { 
  const config = {
    headers : {
    }
  }

  try {
    const response = await axios.get('https://db.ygoprodeck.com/api/v7/cardinfo.php?fname='+ query,config)
    return response.data.data
  } catch {
  }
}

export async function fortniteNews () {
  const config = {
    headers : {

    }
  }

  try {
    const response = await axios.get('https://fortnite-api.com/v2/news/br',config)
    return response.data
  } catch {
     
  }
}