import React, { useEffect, useState } from 'react'
import { collection, getDocs } from "firebase/firestore";
import { recepieDatabase } from '../firebase';
import { Card, Container, Modal, Navbar, Nav} from 'react-bootstrap';

export default function CookbookMain() {
  const [recepieListOutcome, setRecepieListOutcome] = useState([])
  // 
  const [selectedRecepie,setSelectedRecepie] = useState(0)
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  function handleShow (recepieNumber){
      setShow(true)
      setSelectedRecepie(recepieNumber)
  }

  useEffect(() => {
    collectRecepies().then(list => {
      formatRecepieList(list)
    })

  }, [])
  
  async function collectRecepies() {
    const listOfRecepies = []

    const querySnapshot = await getDocs(collection(recepieDatabase, "Recepies"));
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      listOfRecepies.push([doc.id,doc.data()]);
    })

    return listOfRecepies
  }

  function formatRecepieList(collectionOfRecepies) {
    const recepieArray = []

    let count = 0

    while (count < collectionOfRecepies.length) {
      recepieArray.push([collectionOfRecepies[count][0],collectionOfRecepies[count][1].ingredients,collectionOfRecepies[count][1].method])
      count ++
    }
    console.log(recepieArray)
    setRecepieListOutcome(recepieArray)
  }

  return (
    <div style={{ height : '100vh'}} className='center text-center' >
        {/* Navigation bar */}
        <Navbar bg="light" expand="lg">
            <Container>
                <Navbar.Brand href="/">Realm Heart</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                    <Nav.Link href='/anime'>Anime</Nav.Link>
                </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        <br/>
        <div className=' d-lg-flex  justify-content-center' >
        {recepieListOutcome.map((item,i) => 
            <Card style={{ width: '18rem'}} key={i}>   
                <Card.Body>
                    <Card.Title>{item[0]}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">Ingrediants</Card.Subtitle>
                        <Card.Text>
                        <span>Ingredients</span>

                            {item[1].map((ingrediant,i) => (
                                <li key={i}>{ingrediant}</li>
                            ))}

                        </Card.Text>
                    <Card.Link onClick={() => handleShow(i)}>View Method</Card.Link>
                </Card.Body>
            </Card>
        )}

        {recepieListOutcome.length > 0 ? 
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>{recepieListOutcome[selectedRecepie][0]}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <>  
                <h3>Ingredients</h3>
                {recepieListOutcome[selectedRecepie][1].map((ingrediant,i) => 
                <> 
                    <li key={i}>{ingrediant}</li>
                    </>
                )}
                <br/>
                <h3>Method</h3>
                
                <ol>
                {recepieListOutcome[selectedRecepie][2].map((method,i) => 
                    <li key={i}>{method}</li>
                )}
                </ol>
              </>
            </Modal.Body>
          </Modal>
          :''}
          </div>
    </div>
  )
}
