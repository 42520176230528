import React from 'react'

const  DateDisplay = ({ targetDate }) => {

    let date =  new Date (targetDate)
    let year = date.getFullYear();
    let month = date.getMonth()+1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = '0' + dt;
    }
    if (month < 10) {
      month = '0' + month;
    }

    console.log(year+'-' + month + '-'+dt);

  return (
    <span>{dt+'-' + month + '-'+year}</span>
  )
}

export default DateDisplay