import React, { useEffect, useState } from 'react'
import { Card, Carousel, Container, Nav, Navbar } from 'react-bootstrap'
import { fortniteNews, TrendingAnime, TrendingManga, warframeNews } from './ApiCalls'
import CountdownTimer from './Countdown'


export default function Home() {
  const [news,setNews] = useState({})
  const [newsOutcome,setNewsOutcome] = useState([])
  // Fortnite News
  const [fNews,setFNews] = useState({})
  // Trending Anime
  const [trendingAnime,setTrendingAnime] = useState({})
  const [trendingAnimeOutcome,setTrendingAnimeOutcome] = useState([])
    // Trending Manga
    const [trendingManga,setTrendingManga] = useState({})
    const [trendingMangaOutcome,setTrendingMangaOutcome] = useState([])
  // loaded
  const [isLoaded,setIsLoaded] = useState(false)

  // Pulls api data for the page during loading
  useEffect(() => {
    warframeNews()
    .then(response => {
      setNews(response)
      setIsLoaded(true)
      setIsLoaded(false)
    })

    fortniteNews()
    .then((response) => {
        setFNews(response.data)
        setIsLoaded(true)
        setIsLoaded(false)
    })

    TrendingAnime()
    .then(response => {
      setTrendingAnime(response.data)
      setIsLoaded(true)
      setIsLoaded(false)
    })

    TrendingManga()
    .then(response => {
      setTrendingManga(response.data)
      setIsLoaded(true)
    })
    
    
  },[])

// WF news call
  const newsResults = []
  function newsData () {
    
    const length = Object.keys(news).length
    let count = 0

    while (count < length){
      if (news[count].eta.includes('in')) {
        newsResults.push([news[count].eta,news[count].imageLink,news[count].link,news[count].message])
      } else {
        // slice to remove ago section
      newsResults.push([news[count].eta.slice(0,news[count].eta.length -4),news[count].imageLink,news[count].link,news[count].message])
      }
      count ++
    }

    setNewsOutcome(newsResults)

  }

  // trending anime call
  const trendingAnimeResults = []
  function trendingAnimeData () {
    let count = 0

   while (count < trendingAnime.length){
    trendingAnimeResults.push(trendingAnime[count])
    count++
   }
   setTrendingAnimeOutcome(trendingAnimeResults)
  }

  // trending manga call
  const trendingMangaResults = []
  function trendingMangaData () {
    let count = 0

   while (count < trendingManga.length){
    trendingMangaResults.push(trendingManga[count])
    count++
   }
   setTrendingMangaOutcome(trendingMangaResults)
  }

  // Function to be run when page is loaded
  useEffect(() => {
    newsData()
    trendingAnimeData()
    trendingMangaData()
  // eslint-disable-next-line
  },[isLoaded])


  return (
    <>
        <div style={{height:'100vh'}}>
          <Navbar bg="light" expand="lg">
            <Container>
              <Navbar.Brand href="/">Realm Heart</Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  <Nav.Link href="/warframe">Warframe</Nav.Link>
                  <Nav.Link href='/anime'>Anime</Nav.Link>
                  <Nav.Link href='/manga'>Manga</Nav.Link>
                  <Nav.Link href='/yugioh'>Yu-Gi-Oh</Nav.Link>
                  <Nav.Link href='/fortnite'>Fortnite</Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>

            {/* Warframe News */}
            <h1 className='d-flex justify-content-center'><br/> Warframe News</h1>
            <Carousel className="text-sm border-2 border-danger">
              {/*'.slice(0).reverse()' < creates an reverse order list */}
              {newsOutcome.slice(0).reverse().map((item,i) =>
                <Carousel.Item key={i} style={{ maxHeight: '300px'}} >
                  {/* Make an image link to relevent page in a new tab */}
                <a href={item[2]} target="_blank" rel="noreferrer"> <img className='w-100 ' src={item[1]} style={{minHeight :'250px'}} alt="" /> 
                <Carousel.Caption>
                  <h4 style={{ WebkitTextStroke : '.75px black' }} className="text-white">{item[3]}</h4>
                  {item[0].includes('in') ? <span className='badge bg-dark'> {item[0].slice(0,-3)} </span> :<span className='badge bg-dark'>{item[0].slice(0,-3)} ago </span>}
                </Carousel.Caption>
                </a>
              </Carousel.Item>
              )}
            </Carousel>

            {/* Fortnite News */}
            <h1 className='d-flex justify-content-center'><br/> Fortnite News</h1>
            <div className='d-flex w-100 flex-wrap justify-content-center'>
              <img src={fNews.image} alt='' className='img-fluid'/>
            </div>
            

            {/* Trending Anime */}
            <h1 className='d-flex justify-content-center'><br/> Trending Anime</h1>
            <div className='d-flex w-100 flex-wrap justify-content-center'>
              {trendingAnimeOutcome.map((item,i) => {
              return (
                <Card key={i} style={{ width: '20rem' }}>
                <Card.Img variant="top" src={item.attributes.posterImage.large} />
                <Card.Body>
                  <Card.Title>{item.attributes.canonicalTitle}</Card.Title>
                  <span className='badge bg-primary'>Rank : {i+1}</span>
                  {/* Shows Synopsis up to the 300 characters, to the closest '.' */}
                  <Card.Text>{item.attributes.synopsis.substring(0, Math.min(item.attributes.synopsis.substring(0,300).length, item.attributes.synopsis.substring(0,300).lastIndexOf(".")))}</Card.Text>
                </Card.Body>
                <Card.Footer className='text-muted'>
                  Next Release : <CountdownTimer targetDate={item.attributes.nextRelease} type='anime'/>
                  <br/>
                  Episodes : {item.attributes.episodeCount ? item.attributes.episodeCount :  'Unrecorded' }
                  <br/>
                </Card.Footer>
              </Card>
              )
              })
            }
            </div>

            {/* Trending Manga */}
            <h1 className='d-flex justify-content-center'><br/> Trending Manga</h1>
            <div className='d-flex w-100 flex-wrap justify-content-center'>
              {trendingMangaOutcome.map((item,i) => {
              return (
                <Card key={i} style={{ width: '20rem' }}>
                <Card.Img variant="top" src={item.attributes.posterImage.large} />
                <Card.Body>
                  <Card.Title>{item.attributes.canonicalTitle}</Card.Title>
                  <span className='badge bg-primary'>Rank : {i+1}</span>
                  {item.attributes.mangaType === 'oel' ? <span className='badge bg-info'>WEB COMIC</span> : <span className='badge bg-info'>{item.attributes.mangaType.toUpperCase()}</span>}
                  {/* Shows Synopsis up to the 300 characters, to the closest '.' */}
                  <Card.Text>{item.attributes.synopsis.substring(0, Math.min(item.attributes.synopsis.substring(0,300).length, item.attributes.synopsis.substring(0,300).lastIndexOf(".")))}</Card.Text>
                </Card.Body>
                <Card.Footer className='text-muted'>
                  Chapters : {item.attributes.chapterCount ? item.attributes.chapterCount :  'Unrecorded' }
                  <br/>
                </Card.Footer>
              </Card>
              )
              })
            }
            </div>
        </div>
    </>
  )
}