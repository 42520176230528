import React from "react"
import {BrowserRouter as Router, Routes , Route,} from "react-router-dom"
import CookbookMain from "../Cookbook/CookbookMain";
import Test from "../Cookbook/Test";
import Anime from "./Anime";
import Fortnite from "./Fortnite";
import Home from './Home'
import IntelligentMinds from "./IntelligentMinds";
import Warframe from "./Warframe";
import Yugioh from "./Yugioh";

function App() {
  
  return (
      <div className= "d-flex align-items-center justify-content-center" style={{minHeight:"100vh"}}>
        <div className="col">
          <Router>
              <Routes>
                <Route  path="/" element={<Home/>} />
                <Route path='/warframe' element={<Warframe/>}/>
                <Route path='/anime' element={<Anime/>} />
                <Route path='/yugioh' element={<Yugioh/>}/>
                <Route path='/fortnite' element={<Fortnite/>}/>
                <Route path = '/cookbook' element={<CookbookMain/>}/>
                <Route path = '/test' element={<Test/>}/>
                <Route path = '/intminds' element={<IntelligentMinds/>}/>
              </Routes>
          </Router>
        </div>
      </div>
    )
}

export default App;

// to depoy run npm run build then firebase deploy