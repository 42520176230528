import React from 'react'
import { Button, Card, Col, Container, Figure, FloatingLabel, Form, Row } from 'react-bootstrap'
import LogoWhite from '../Images/IntMinds/Intelligent Minds-logos_white.png'
import RoyImg from '../Images/IntMinds/roy-van-rijn.jpg'
import JaylanAzer from '../Images/IntMinds/jaylan-azer.jpg'
import {BsClockFill, BsFillCalendarEventFill, BsFillPersonFill} from 'react-icons/bs';

export default function IntelligentMinds() {
  return (

    <div style={{height:'100vh'}}>

    <div className='d-flex text-center bg-black'>
          <img className='row' src={LogoWhite} width='100px' height='100px' alt=''/>
          <h2 className='col align-self-center text-white text-center'>The Ai initiative</h2>
    </div>

    <br/>
    <div className='d-lg-flex '>
      <Card className='w-lg-75 col ms-2 border-light' >
        <span>Live Expert Panel and Q&A</span>
        <h2>The Ai initiative</h2>
        <div>
        <p className=' mb-2 '>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lectus mauris ultrices eros in cursus. Cras semper auctor neque vitae tempus quam. Semper auctor neque vitae tempus quam pellentesque nec nam aliquam. Duis at consectetur lorem donec massa sapien faucibus et. Nunc sed velit dignissim sodales ut. Integer malesuada nunc vel risus commodo. Varius sit amet mattis vulputate enim nulla aliquet porttitor. Consequat interdum varius sit amet mattis vulputate enim. </p>
        </div>
        <br/>
        <div className='d-flex align-items-center'>
        <BsFillCalendarEventFill/><span className='mx-1'>Saturday,Sunday February 27,28, 2024</span></div>
        <div className='d-flex align-items-center'><BsClockFill/><span className='mx-1'> 10:00AM-5:00PM GMT</span></div>
        <br/>
		<div className='d-flex align-items-start'>
		<BsFillPersonFill/>
        <ul style={{listStyle:'none', margin:0,padding:0,overflow:"hidden"}} className='mx-1'>
          <li style={{float:'left'}}>Roy van Rijn | Speaker</li>
		  <br/>
          <li style={{float:'left'}}>Jaylan Azer  | Speaker</li>
        </ul>
		</div>

      </Card>

        <Form className=' col w-lg-25 p-3 mx-lg-5 bg-dark text-center' >
          <br/>
          <Row>
            <Col>
                <Form.Group>
                  <FloatingLabel label='First Name' >
                  <Form.Control type="text" placeholder=' ' />
                  </FloatingLabel>
                </Form.Group>
            </Col>
            <Col>
                <Form.Group>
                  <FloatingLabel label='Last Name'>
                  <Form.Control type="text" placeholder=' ' />
                  </FloatingLabel>
                </Form.Group>
            </Col>
          </Row>
          <br/>
          <Row>
            <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <FloatingLabel label='Email Address'>
                    <Form.Control type="email" placeholder="Enter email" />
                  </FloatingLabel>
                </Form.Group>
            </Col>
            <Col>
                <Form.Group>
                  <FloatingLabel label='Phone'>
                  <Form.Control type="number" placeholder='Phone' />
                  </FloatingLabel>
                </Form.Group>
            </Col>
          </Row>
        <Form.Text className="text-muted"> We'll never share your details with anyone else. </Form.Text>

        <br/><br/>
        <Button variant="light" type="Submit">
          Reserve My Spot
        </Button>
        </Form>

    </div>

    <Container className='d-lg-flex justify-content-center text-center'>
      <Figure className=' p-5 col '>
        <h5>Jaylan Azer </h5>
        <Figure.Image
        width={250}
        height={250}
        alt=""
        src={JaylanAzer}/>
        <Figure.Caption >Assistant Professor of Marketing At The University of Glasgow</Figure.Caption>
        <p>Speaker</p>
      </Figure>
      <Figure className=' p-5 col'>
        <h5>Roy van Rijn</h5>
        <Figure.Image
        width={250}
        height={250}
        alt=""
        src={RoyImg}/>
        <Figure.Caption>Director at OpenValue Rotterdam and a Java Champion</Figure.Caption>
        <p>Speaker</p>
      </Figure>
    </Container>

    <Card className=' text-center'>
      <Card.Title>A conversation with experts in Artificial Intelligence</Card.Title>
      <p className='w-lg-25'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lectus mauris ultrices eros in cursus</p>
    </Card>


{/*     <footer className=' justify-content-center'>
      <img src={LogoBlack} width='150px' height='150px'/>
      <p>Copyright 2019, Intelligent Minds. <a href='#'>Privicy Policy</a></p>
    </footer> */}
    </div>
  )
}