import React, { useEffect, useState } from 'react'
import { Alert, Card, Container, Image, Nav, Navbar, NavDropdown, OverlayTrigger, ProgressBar, Table, Tooltip } from 'react-bootstrap'
import { warframeWorldState } from './ApiCalls'
import coldIcon from '../Images/coldIcon.png'
import warmIcon from '../Images/warmIcon.png'
import dayIcon from '../Images/dayIcon.png'
import nightIcon from '../Images/nightIcon.png'

import CountdownTimer from './Countdown'

export default function Warframe() {
  // Platform picker
  const [platform, setPlatform] = useState('pc')
  // Sorties
  const [sortie, setSortie] = useState({})
  const [sortieOutcome, setSortieOutcome] = useState([])
  // Arbitrations
  const [arbitration, setArbitration] = useState({})
  const [arbitrationOutcome, setArbitrationOutcome] = useState([])
  // Construction Progress
  const [construction, setConstruction] = useState({})
  const [constructionOutcome, setConstructionOutcome] = useState([])
  // Void Trader
  const [trader, setTrader] = useState({})
  const [traderOutcome, setTraderOutcome] = useState([])
  // Nightwave
  const [nightwave, setNightwave] = useState({})
  const [nightwaveOutcome, setNightwaveOutcome] = useState([])
  // Invasions
  const [currentInvasionsOutcome, setCurrentInvasionOutcome] = useState([])
  // Cycle Timers
  const [currentCycleTimersOutcome, setCurrentCycleTimersOutcome] = useState([])
  //  Archon Hunt
  const [currentArchonHuntOutcome, setCurrentArchonHuntoutcome] = useState([])

  // ivokes section on load
  useEffect(() => {
    warframeWorldState(platform)
      .then(response => {
        sortieVariants(response)
        arbitrations(response)
        constructionProgress(response)
        voidTrader(response)
        nightwaveHandle(response)
        invasions(response)
        cycleTimers(response)
        archonHunt(response)
      })
      .catch(() => {
        return ''
      })

  }, [platform])

  // Returns an array of the sortie missions
  function sortieVariants(worldstate) {
    setSortie(worldstate['sortie'])
    const variant = worldstate['sortie'].variants

    const stage1 = []
    stage1.push(variant[0].missionType + ' - ' + variant[0].node + ' | ' + variant[0].modifier)

    const stage2 = []
    stage2.push(variant[1].missionType + ' - ' + variant[1].node + ' | ' + variant[1].modifier)

    const stage3 = []
    stage3.push(variant[2].missionType + ' - ' + variant[2].node + ' | ' + variant[2].modifier)

    const sortieResults = []
    sortieResults.push(stage1, stage2, stage3)

    setSortieOutcome(sortieResults)
  }

  function arbitrations(worldstate) {
    setArbitration(worldstate['arbitration'])
    // 🔽Allows Content to Render Results When The Page Is Loaded🔽
    const arbitration2 = worldstate['arbitration']

    const enemy = []
    enemy.push(arbitration2.enemy)

    const type = []
    type.push(arbitration2.type)

    const arbitrationResults = []
    arbitrationResults.push(enemy, type)

    setArbitrationOutcome(arbitrationResults)
  }

  function constructionProgress(worldstate) {
    setConstruction(worldstate['constructionProgress'])
    // 🔽Allows Content to Render Results When The Page Is Loaded🔽
    const construction2 = worldstate['constructionProgress']

    const fomorianProgress = []
    fomorianProgress.push(construction2.fomorianProgress)

    const razorbackProgress = []
    razorbackProgress.push(construction2.razorbackProgress)

    const constructionResults = []
    constructionResults.push(fomorianProgress, razorbackProgress)

    setConstructionOutcome(constructionResults)

  }
  function voidTrader(worldstate) {
    setTrader(worldstate['voidTrader'])
    // 🔽Allows Content to Render Results When The Page Is Loaded🔽
    const trader2 = worldstate['voidTrader']
    const inventory = []
    let count = 0
    // adds each item to the inventory variable
    while (count < trader2.inventory.length) {
      inventory.push([trader2.inventory[count].item, trader2.inventory[count].ducats, trader2.inventory[count].credits])
      count++
    }

    setTraderOutcome(inventory)
  }

  function nightwaveHandle(worldstate) {
    setNightwave(worldstate['nightwave'])
    // 🔽Allows Content to Render Results When The Page Is Loaded🔽
    const nightwave2 = worldstate['nightwave']

    const nightwaveChallenges = []
    let count = 0

    while (count < nightwave2.activeChallenges.length) {
      nightwaveChallenges.push(nightwave2.activeChallenges[count])
      count++
    }

    setNightwaveOutcome(nightwaveChallenges)
  }

  function invasions(worldstate) {
    // 🔽Allows Content to Render Results When The Page Is Loaded🔽
    const invasion = worldstate['invasions']

    const invasionsDetails = []
    let count = 0

    while (count < invasion.length) {
      invasionsDetails.push([invasion[count].attacker, invasion[count].defender, invasion[count].completion, invasion[count].node, invasion[count].desc, invasion[count].eta])
      count++
    }
  
    setCurrentInvasionOutcome(invasionsDetails)
  }

  function cycleTimers(worldstate) {
    // 🔽Allows Content to Render Results When The Page Is Loaded🔽
    const earthCycle = worldstate['earthCycle']
    const cetusCycle = worldstate['cetusCycle']
    const vallisCycle = worldstate['vallisCycle']
    const cambionCycle = worldstate['cambionCycle']

    const cycleDetails = []
    cycleDetails.push(['earth', earthCycle.expiry, earthCycle.state], ['cetus', cetusCycle.expiry, cetusCycle.state], ['vallis', vallisCycle.expiry, vallisCycle.state], ['cambion', cambionCycle.expiry, cambionCycle.active])
    setCurrentCycleTimersOutcome(cycleDetails)
  }
  
  function archonHunt(worldstate){
    // 🔽Allows Content to Render Results When The Page Is Loaded🔽#
    const archon = worldstate['archonHunt']

    const archonMissions = []
    let count = 0
    while (count < archon.missions.length){
      archonMissions.push([archon.missions[count].node,archon.missions[count].type])
      count ++
    }

    const archonDetails = []
    archonDetails.push([archon.expiry, archon.boss,archonMissions])
    setCurrentArchonHuntoutcome(archonDetails)
  }

  return (
    <>
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand href="/">Realm Heart</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href='/anime'>Anime</Nav.Link>
              <Nav.Link href='/yugioh'>Yu-Gi-Oh</Nav.Link>
              <Nav.Link href='/fortnite'>Fortnite</Nav.Link>
            </Nav>
            {/* {Platform Picker} */}
            {/* <Nav>
              <NavDropdown title={`Platform (${platform.toUpperCase()})`}>
                <NavDropdown.Item onClick={(() => setPlatform('pc'))}>PC</NavDropdown.Item>
                <NavDropdown.Item onClick={(() => setPlatform('ps4'))}>PS4</NavDropdown.Item>
                <NavDropdown.Item onClick={(() => setPlatform('xbox'))}>Xbox</NavDropdown.Item>
                <NavDropdown.Item onClick={(() => setPlatform('Switch'))}>Switch</NavDropdown.Item>
              </NavDropdown>
            </Nav> */}
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div style={{ minHeight: '100vh' }}>

        {/* Dynamic Section */}
        <div className=' d-lg-flex ' >

          {/* Sortie */}
          <Card className=' text-center col'>
            <Card.Body>
              <Card.Title>Sortie</Card.Title>

              {sortie ? <>
                <span>{sortie.faction}: {sortie.boss} </span>
                {sortieOutcome.map((item, i) => <li key={i}>{item}</li>)}
              </> : ""}
            </Card.Body>
          </Card>

          {/* Arbitration */}
          <Card className=' text-center col'>
            <Card.Body>
              <Card.Title>Arbitration</Card.Title>

              {arbitration ? <>
                <span> {arbitration.node} </span>
                {/* Checks if there is a arbitration then displays all features*/}
                {arbitration.expiry ? arbitrationOutcome.map((item, i) => <li key={i}>{item}</li>) : <Alert variant='info'>No Current Arbitration Alert</Alert>}
                {arbitration.archwing ? <span className='badge bg-success'>Archwing</span> : null}
                {arbitration.sharkwing ? <span className='badge bg-success'>Sharkwing</span> : null}
                {arbitration.expiry ? <CountdownTimer targetDate={arbitration.expiry} /> : ''}
              </> : ''}

            </Card.Body>
          </Card>

          {/* Construction Progress */}
          <Card className=' text-center col'>
            <Card.Body>
              <Card.Title>Construction</Card.Title>

              {construction ? <>
                <span>Fomorian </span>
                {construction.fomorianProgress >= 100 ? <ProgressBar variant='danger' animated now={constructionOutcome[0]} label={`${constructionOutcome[0]}%`} /> : <ProgressBar variant='success' now={constructionOutcome[0]} label={`${constructionOutcome[0]}%`} />}
                <span>Razorback </span>
                {construction.razorbackProgress >= 100 ? <ProgressBar variant='danger' animated now={constructionOutcome[1]} label={`${constructionOutcome[1]}%`} /> : <ProgressBar variant='success' now={constructionOutcome[1]} label={`${constructionOutcome[1]}%`} />}
              </> : ''}
            </Card.Body>
          </Card>

          {/* Cycle Timers */}
          <Card className=' text-center col'>
            <Card.Body>
              <Card.Title>Cycle Timers</Card.Title>
              <div className='d-md-flex flex-md-wrap justify-content-center '>
                {currentCycleTimersOutcome.map((item, i) =>
                  <div style={{ border: 'solid', borderColor: 'white' }} key={i}>
                    <h5>{item[0].toUpperCase()}</h5>
                    {item[2] === 'day' || item[2] === 'night' || item[2] === 'vome' || item[2] === 'fass' ? item[2] === 'day' || item[2] === 'fass' ? <img src={dayIcon} alt='day' width='24px' height='24px' /> : <img src={nightIcon} alt='night' width='24px' height='24px' /> : item[2] === 'warm' ? <img src={warmIcon} alt='warm' width='24px' height='24px' /> : <img src={coldIcon} alt='cold' width='24px' height='24px' />}
                    <br />
                    {item[2] === 'day' || item[2] === 'night' ? item[2] === 'day' ? <span>Day</span> : <span>Night</span> : item[2] === 'vome' || item[2] === 'fass' ? item[2] === 'vome' ? <span>Vome</span> : <span>Fass</span> : item[2] === 'warm' ? <span>Warm</span> : <span>Cold</span>}
                    <br />
                    <CountdownTimer targetDate={item[1]} />
                  </ div>
                )}
              </div>
            </Card.Body>
          </Card>

          {/* Archon Hunts */}
          <Card className=' text-center col '>
            <Card.Body>
              <Card.Title>Archon Hunt</Card.Title>
              <div>
                {currentArchonHuntOutcome.map((item,i) => 
                <div key={i}>
                  <span>{item[1]}</span>
                  <li>{item[2][0]}</li>
                  <li>{item[2][1]}</li>
                  <li>{item[2][2]}</li>
                  <CountdownTimer targetDate={item[0]}/>
                </div>
                )}
                
              </div>
            </Card.Body>
          </Card>

        </div>

        {/* Nightwave */}
        <Card >
          <Card.Body>
            <Card.Title className=' text-center'>Nightwave</Card.Title>

            {nightwave ? <>
              <Table bordered variant="dark" size='sm' >
                <thead>
                  <tr>
                    <th>Mission</th>
                  </tr>
                </thead>
                <tbody>
                  {nightwaveOutcome.map((item, i) =>
                    item.isDaily ?
                      <tr key={i}>
                          <td>
                            {item.title}{item.reputaion}
                            <OverlayTrigger placement='right' overlay={<Tooltip id={i}>Daily</Tooltip>}>
                              <img src='https://raw.githubusercontent.com/WFCD/genesis-assets/master/webp/nightwave/daily.webp' width='24px' height='15px' alt='Daily' />
                            </OverlayTrigger>
                            <p>{item.desc}</p>
                            <span className='d-grid justify-content-end'><CountdownTimer targetDate={item.expiry} /></span>
                          </td>
                      </tr> : item.isElite ?
                        <tr key={i}>
                            <td>
                              {item.title}{item.reputaion}
                              <OverlayTrigger placement='right' overlay={<Tooltip id={i}>Elite</Tooltip>}>
                                <img src='https://raw.githubusercontent.com/WFCD/genesis-assets/master/webp/nightwave/elite.webp' width='24px' height='24px' alt='Daily' />
                              </OverlayTrigger>
                              <p>{item.desc}</p>
                              <span className='d-grid justify-content-end'><CountdownTimer targetDate={item.expiry} /></span>
                            </td>
                        </tr> : <tr key={i}>
                            <td>
                              {item.title}{item.reputaion}
                              <OverlayTrigger placement='right' overlay={<Tooltip id={i}>Weekly</Tooltip>}>
                                <img src='https://raw.githubusercontent.com/WFCD/genesis-assets/master/webp/nightwave/weekly.webp' width='24px' height='24px' alt='Daily' />
                              </OverlayTrigger>
                              <p className=' col-2'>{item.desc}</p>
                              <span className='d-grid justify-content-end'><CountdownTimer targetDate={item.expiry} /></span>
                            </td>
                        </tr>
                  )}
                </tbody>
              </Table>
            </> : ''}
          </Card.Body>
        </Card>

        {/* Void Trader */}
        <Card className=' text-center'>
          <Card.Body>
            <Card.Title>Void Trader</Card.Title>

            {trader.active ?
              <>
                <p>{trader.location}</p>
                <CountdownTimer targetDate={trader.expiry} />
                <Table bordered variant="dark" size='sm'>
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Ducats</th>
                      <th>Credits</th>
                    </tr>
                  </thead>
                  <tbody>
                    {traderOutcome.map((item, i) =>
                      <tr key={i}>
                        <td>{item[0]}</td>
                        <td>{item[1]}</td>
                        <td>{item[2]}</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </>
              :
              <>
                <span> Arrives at {trader.location} in </span>
                <CountdownTimer targetDate={trader.activation} />
              </>
            }

          </Card.Body>
        </Card>

        {/* Invations */}
        <Card className='text-center'>
          <Card.Body>
            <Card.Title>Invations</Card.Title>
            <Table bordered variant="dark" size='sm' >
              <tbody>
                {currentInvasionsOutcome.map((item, i) =>
                  <>
                  {!item[5].includes('-') ?
                    <tr key={i}>
                      <td><>
                        <h6 className=' text-white'>{item[3]}- {item[4]}</h6>
                                                
                               {/* attacker code */}
                        {/* check if reward is credits (the place holder) */}
                        { !item[0].reward ? '' :
                          // checks if there is a reward
                          item[0].reward.countedItems.length === 0 ?

                          <OverlayTrigger key='top' placement='top'
                          overlay={
                            <Tooltip>
                              <>
                                <img src={item[0].reward.thumbnail} width='30px' height='30px' alt='' />
                              </>
                            </Tooltip>
                          }>
                          <Image src={item[0].reward.thumbnail} alt={item[0].reward.asString} width='30px' height='30px' />
                        </OverlayTrigger>
                        :
                        <OverlayTrigger key='top' placement='top'
                          overlay={
                            <Tooltip>
                              <>
                                <img src={item[0].reward.thumbnail} width='30px' height='30px' alt='' />
                                <br />
                                {item[0].reward.countedItems[0].count} {item[0].reward.countedItems[0].type}
                              </>
                            </Tooltip>
                          }>
                          <Image src={item[0].reward.thumbnail} alt={item[0].reward.asString} width='30px' height='30px' />
                        </OverlayTrigger>
                    }
                    {item[2] < 100 ? item[2].toString().slice(0, 5) + '% - Ends in  ' + item[5] : '100% - Ends in  ' + item[5]}

                       {/* defender code */}
                    {/* check if reward is credits (the place holder) */}
                    {!item[1].reward? '' :
                      // checks if there is a reward
                      item[1].reward.countedItems.length === 0 ?
                      <OverlayTrigger key='top1' placement='top1'
                      overlay={
                        <Tooltip>
                          <>
                            <img src={item[1].reward.thumbnail} width='30px' height='30px' alt='' />
                          </>
                        </Tooltip>
                      }>
                      <Image src={item[0].reward.thumbnail} alt={item[1].reward.asString} width='30px' height='30px' />
                    </OverlayTrigger>
                    :
                    <OverlayTrigger key='top1' placement='top1'
                      overlay={
                        <Tooltip>
                          <>
                            <img src={item[1].reward.thumbnail} width='30px' height='30px' alt='' />
                            <br />
                            {item[1].reward.countedItems[0].count} {item[1].reward.countedItems[0].type}
                          </>
                        </Tooltip>
                      }>
                      <Image src={item[1].reward.thumbnail} alt={item[1].reward.asString} width='30px' height='30px' />
                    </OverlayTrigger>
                    }  
                 
                                
                        <ProgressBar>  {/* add image of item at end of progress bar*/}
                          <ProgressBar variant="success" now={item[2]} key={1} />
                          <ProgressBar variant="danger" now={100 - item[2]} key={2} />
                        </ProgressBar>
                      </></td>
                    </tr> : ''
                  }
                </>
                  )
                  }
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </div>
    </>
  )
}