import React, { useEffect, useState } from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { fortniteNews } from './ApiCalls'
import DateDisplay from './DateDisplay'

export default function Fortnite() {
    const [fNews,setFNews] = useState({})



    useEffect(() => {
        fortniteNews()
        .then((response) => {
            setFNews(response.data)
            console.log(response.data)
        })
        },[])
    
  return (
  <>
  <div style={{ height : '100vh'}} className='center text-center' >
    <Navbar bg="light" expand="lg">
    <Container>
        <Navbar.Brand href="/">Realm Heart</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
            <Nav.Link href="/warframe">Warframe</Nav.Link>
            <Nav.Link href='/anime'>Anime</Nav.Link>
            <Nav.Link href='/yugioh'>Yu-Gi-Oh</Nav.Link>
        </Nav>
        </Navbar.Collapse>
    </Container>
    </Navbar>

    <h1>Fortnite</h1>
    <div className='text-center'>
        Updated : <DateDisplay targetDate={fNews.date}/>
        <br/>
        <img src={fNews.image} alt='' className='img-fluid'/>
    </div>

  </div>
  </>
    
  )
}