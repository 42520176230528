import React, { useRef, useState } from 'react'
import { Alert, Button, Card, Container, Form, Nav, Navbar, Spinner } from 'react-bootstrap'
import { GoSearch } from 'react-icons/go'
import { searchYugiohCard } from './ApiCalls'

export default function Yugioh() {
    const searchRef = useRef()
    const [isLoading,setIsLoading] = useState(false)
    const [error,setError] = useState(false)
    const [searched,setSearched] = useState(false)
    // card search
    const [searchedCardOutcome, setSearchedCardOutcome] = useState([])
    const [searchValue,setSearchValue] = useState('')
    // card details
    const [cardId,setCardId] = useState([0])

    function yugiohSearch (e){
        e.preventDefault()
        setIsLoading(true)
        setCardId(0)

        searchYugiohCard(searchRef.current.value)
        .then((response) => {
            if (response === undefined){
                setError(true)
                setSearchedCardOutcome([])
            } else {
                setError(false) 
                setSearchedCardOutcome(response)
            }

            setIsLoading(false)
            setSearched(true)
            setSearchValue(searchRef.current.value)

        }).catch(setError(true))
    }

    function cardDetails( card ){
        setCardId(card)
        console.log(card);
    }
  return (
    <div style={{ height : '100vh'}}>
        <Navbar bg="light" expand="lg">
            <Container>
                <Navbar.Brand href="/">Realm Heart</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                    <Nav.Link href="/">Home</Nav.Link>
                    <Nav.Link href='/anime'>Anime</Nav.Link>
                    <Nav.Link href='/fortnite'>Fortnite</Nav.Link>
                </Nav>
                </Navbar.Collapse>
            </Container>
            </Navbar>

            <Container>
                <Form onSubmit={yugiohSearch} className=' text-center'>
                        <Form.Label>Card Search</Form.Label>
                        <Form.Group id='search' className='d-flex justify-content-center'>
                            <Form.Control ref={searchRef} placeholder='Search Card' required/>
                            <Button type='submit' disabled={isLoading} ><GoSearch/></Button>
                        </Form.Group> 
                </Form>
                <br/>
                {/* Results should only show if there was a valid */}



                <div> 
                    {/* loading anim */}
                    <div className=' text-center'>
                        {isLoading ?<Spinner animation="border" variant="primary" /> : ''}
                        
                    </div>

                    {/* search results */}
                    {cardId === 0 ?
                    <>
                        {searchedCardOutcome.length > 0 ? <h1>Cards With '{searchValue}' In Their Name</h1> : '' }
                        <div className='d-flex w-100 flex-wrap justify-content-center'>
                            {searchedCardOutcome.map((item,i) => {
                            return (
                            <Card key={i} >
                                <Card.Img variant='top' src={item.card_images[0].image_url} width="168" height="246" onClick={() => cardDetails(item.id)}/>
                            </Card>
                            )
                            })} 
                        </div>
                     </> : 
                        <div>
                            {searchedCardOutcome.map((item,i) => {
                                if (item.id === cardId){
                                    return(
                                        <Card key={i} className='secondary'>
                                            <Card.Header >{item.name}</Card.Header>
                                            <Card.Img src={item.card_images[0].image_url_cropped} />
                                            <Card.Body>
                                                {item.desc}
                                            </Card.Body>
                                        </Card> 
                                    )
                                }
                            })}
                        </div>
                     }
                </div>

                {  searched && error ? 
                    <Alert variant='danger'>
                        <Alert.Heading>No Result</Alert.Heading>
                        <p>Please try again</p>
                    </Alert> :''}

            </Container>
            
    </div>

    
  )
}

