import React from 'react';
import { useCountdown } from '../hooks/useCountdown';

const CountdownTimer = ({ targetDate, type }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (hours === 0 && days === 0) {
    return (
        <span className='badge bg-secondary'>{minutes}m {seconds}s</span>
    );
  } else if (days === 0) {
    return (
    <span className='badge bg-secondary'>{hours}h {minutes}m {seconds}s</span>
    )
  } else if ( days < 1 && hours<1 && minutes<1 && seconds < 1) {
    if (type === 'anime') {
      return (
        <span className='badge bg-info'> Aired </span>
      )
    }
    return (
       <span className='badge bg-danger'> Expired </span>
    )
  }else{
    
    return (
        <span className='badge bg-secondary'>{days}d {hours}h {minutes}m {seconds}s</span>
        
    );
  }
};

export default CountdownTimer;