import React, { useRef, useState } from 'react'
import { Alert, Button, Card, Container, Form, Nav, Navbar, Spinner } from 'react-bootstrap'
import { GoSearch } from 'react-icons/go'
import { searchAnime } from './ApiCalls'

export default function Anime() {
    const searchRef = useRef()
    const [isLoading,setIsLoading] = useState(false)
    const [error,setError] = useState(false)
    // Anime Search
    const [searchedAnimeOutcome,setSearchedAnimeOutcome] = useState([])

    function animeSearch(e) {
        e.preventDefault()
        setIsLoading(true)

        const searchResults = []

        searchAnime(searchRef.current.value)
        .then( response => {
            setError(false)
            let count = 0
            while (count < response.length) {
                searchResults.push(response[count])
                count ++
            }
            
            setIsLoading(false)
            setSearchedAnimeOutcome(searchResults)
        }).catch(() => {
                setError(true)
                setIsLoading(false)
                setSearchedAnimeOutcome([])
        })
    }

  return (
    <>
        <div style={{height : '100vh'}}>
            <Navbar bg="light" expand="lg">
                <Container>
                <Navbar.Brand href="/">Realm Heart</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                    <Nav.Link href="/">Home</Nav.Link>
                    <Nav.Link href='/warframe'>Warframe</Nav.Link>
                    <Nav.Link href='/yugioh'>Yu-Gi-Oh</Nav.Link>
                    <Nav.Link href='/fortnite'>Fortnite</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
                </Container>
            </Navbar>

            {/* anime search */}
            <Container>
                <Form onSubmit={animeSearch} className=' text-center'>
                    <Form.Label>Anime Search</Form.Label>
                    <Form.Group id='search' className='d-flex justify-content-center'>
                        <Form.Control ref={searchRef} placeholder='Search' required/>
                        <Button type='submit' disabled={isLoading} ><GoSearch/></Button>
                    </Form.Group>
                    
                </Form>
                <br/>
                <div className=' text-center'>
                    {isLoading ?<Spinner animation="border" variant="primary" /> : ''}
                    {searchedAnimeOutcome.length > 0 ? <h1>Top 10 Results</h1> : '' }
                </div>
                {error ? <Alert variant='danger'>
                    <Alert.Heading>No Result</Alert.Heading>
                    <p>Please try again</p>
                    </Alert> : ''}
                
                
                <div className='d-flex w-100 flex-wrap justify-content-center'>
                {searchedAnimeOutcome.map((item,i) => {
                    return(
                    <Card key={i} style={{ width: '18rem' }}>
                        {/* Shows original size image with link */}
                    <a href={item.myanimelist_url} target="_blank" rel="noreferrer"> <Card.Img variant="top" src={item.picture_url.replace('/50x70','').replace('/r','')} style={{ resize : 'contain' }} /> </a>
                    <Card.Body>
                        <Card.Title>{item.title}</Card.Title>
                        {/* Shows Synopsis up to the 300 characters, to the closest '.' */}
                        <Card.Text>{item.description.substring(0, Math.min(item.description.substring(0,300).length, item.description.substring(0,300).lastIndexOf(".")))}</Card.Text>
                    </Card.Body>
                    </Card>
                    )
                })}
                </div>
            </Container>
        </div>
    </>
  )
}