import React, { useState } from 'react'
import {  Button, Form, InputGroup, Modal,Tab, Tabs } from 'react-bootstrap'

export default function Test() {
    const [ingredientField, setIngredientField] = useState([{}]);   

    function handleFormChange(index,event) {
        let data = [...ingredientField];
        data[index][event.target.name] = event.target.value;
        setIngredientField(data);
    }
 
 function addFields() {
    let newfield = {}

    setIngredientField([...ingredientField, newfield])


 }

 const removeFields = (index) => {
    let data = [...ingredientField];
    data.splice(index,1)
    setIngredientField(data)
}

const resetFields  = () => {
    setIngredientField([{}])
}

const Submit = (e) => {
    e.preventDefault();

    ingredientField.forEach((element,i) => {
        console.log()
        if (element.Ingredient === undefined || element.Ingredient === ""   ){
            delete ingredientField[i]
        } else {
            
        }
        // ingredientField.splice(i)
    });

    console.log(ingredientField)   
    // resets the form
    setIngredientField([{}])
}


  return (
    <div style={{ height : '100vh'}} className='center text-center' > 
        <Modal show className='center text-center'>
            <Modal.Body>
                <Form >
                    <Form.Group>
                        <Form.Label>Recepie Name</Form.Label>
                        <Form.Control type='text'/>
                    </Form.Group>
                    <br/>
                    <Tabs defaultActiveKey="ingredientsList">
                        <Tab eventKey="ingredientsList" title="Ingredients">
                            <Form.Label>Ingredients</Form.Label>
                                {ingredientField.map((ingredient,i) => 
                                <>
                                    <Form.Group key={i} >
                                        <InputGroup size='sm' >
                                            <InputGroup.Text>{i+1}.</InputGroup.Text>
                                                <Form.Control name='Ingredient' type='text' onChange={event => handleFormChange(i,event)}/>      
                                                {(i+1 === ingredientField.length) && i > 0 ? <Button onClick={() => removeFields(i)}>-</Button>:""}

                                                {/* fix the first value nt issuing */}
                                                {(i+1 === ingredientField.length)?
                                                ingredientField.length > 0 && (ingredientField[i].Ingredient !== undefined && ingredientField[i].Ingredient.trim() !== ""  ) ? 
                                                    <Button  variant='info' onClick={addFields} >+</Button> :
                                                    <Button  variant='info' onClick={addFields} disabled >+</Button> : ""  }
                                        </InputGroup>
                                    </Form.Group> 
                                </>
                                )}

                                <br/>
                        <Button onClick={resetFields}>Reset</Button> 

                            </Tab>
                                <Tab eventKey="methodList" title="Method">
                                <Form.Group>
                                    <Form.Label>Methods</Form.Label>
                                    <Form.Control type='text'/>
                                </Form.Group>
                                <br/>
                            </Tab>
                    </Tabs>

                    <br/>
                    {ingredientField.length > 0 && (ingredientField[0].Ingredient !== undefined && ingredientField[0].Ingredient.trim() !== ""  ) ? 
                    <Button type="submit" className="mb-2" onClick={Submit}>Submit</Button>:
                    <Button type="submit" className="mb-2" onClick={Submit} disabled>Submit</Button>}
                </Form>
            </Modal.Body>
        </Modal>
    </div>
  )
}